@font-face {
    font-family: 'SegoeUI-Regular';
    src: url('../fonts/SegoeUI-Regular/SegoeUI-Regular.eot');
    src: url('../fonts/SegoeUI-Regular/SegoeUI-Regular.svg') format('svg'), url('../fonts/SegoeUI-Regular/SegoeUI-Regular.woff') format('woff'), url('../fonts/SegoeUI-Regular/SegoeUI-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SegoeUI-Semibold';
    src: url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.eot');
    src: url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.svg') format('svg'), url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.woff') format('woff'), url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SegoeUI-Bold';
    src: url('../fonts/SegoeUI-Bold/SegoeUI-Bold.eot');
    src: url('../fonts/SegoeUI-Bold/SegoeUI-Bold.svg') format('svg'), url('../fonts/SegoeUI-Bold/SegoeUI-Bold.woff') format('woff'), url('../fonts/SegoeUI-Bold/SegoeUI-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    color: #262626;
    font-family: 'SegoeUI-Regular', sans-serif !important;
    font-size: 13px !important;
    background: #ebeef1;
}

.red {
    color: red;
}

div, span, h4 {
    transition: all 0.3s ease-in-out !important;
}

.mt0 {
    margin-top: 0 !important;
}

.mb7 {
    margin-bottom: 7px !important;
}

.mb15 {
    margin-bottom: 15px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}
.mr0 {
    margin-right: 0 !important;
}
.mr10 {
    margin-right: 10px !important;
}

.ml10 {
    margin-left: 10px !important;
}

.ml20 {
    margin-left: 20px;
}

.mt5 {
    margin-top: 5px !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mt15 {
    margin-top: 15px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mt30 {
    margin-top: 30px !important;
}

.pb0 {
    padding-bottom: 0 !important;
}

.pd0 {
    padding: 0 !important;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.bold {
    font-weight: bold;
}

.font-semibold {
    font-family: 'SegoeUI-Semibold', sans-serif;
}

.f12 {
    font-size: 12px !important;
}

.f13 {
    font-size: 13px !important;
}

.bodyColor {
    color: #262626 !important;
}

.widthFluid {
    width: 100% !important;
}

label.subError {
    color: red;
    margin: 20px;
    font-size: 15px;
}

.datePicker .rmdp-input {
    margin-left: 330px !important;
}

.nF.datePicker {
    margin-left: 330px !important;
}

.nF1.datePicker {
    margin-left: 350px !important;
}

.datePicker .lblnFDate {
    position: absolute;
    margin-top: 10px;
    margin-left: 250px;
}

.ui.dimmer {
    background-color: rgba(1, 74, 81, 0.9) !important;
}

.ui.header {
    font-family: 'SegoeUI-Semibold', sans-serif !important;
}

.bodyColor {
    color: #273140;
}

.primaryColor {
    color: #d67d38 !important;
}

.colorGray {
    color: #707070 !important;
}

.bg-color-primary {
    background: #1a89a7 !important;
}

.clrGreen {
    color: #6cb043;
    font-weight: 600;
}

.clrRed {
    color: #e60d09;
    font-weight: 600;
}

.bg-color-primary {
    background: #1a89a7 !important;
}

.color-white {
    color: #fff !important;
}

.labelStyle {
    font-family: 'SegoeUI-Semibold', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
}

.banner .tooltip {
    position: relative;
    display: inline-block;
    opacity: unset !important;
    text-align: center;
    width: 100%;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 15px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.overflow-scroll {
    max-height: 600px;
    overflow: auto;
}

.modalOverflowUnset {
    overflow: unset !important;
}

.modalOverflowUnset .content {
    overflow: unset !important;
}

.cursorPointer {
    cursor: pointer !important;
}

.displayInline {
    display: inline !important;
}

.displayBlock {
    display: block !important;
}

.d-InlineBlock {
    display: inline-block !important;
}

sub {
    font-size: 12px;
}

.text-uppercase {
    text-transform: uppercase;
}

.borderBottomGray {
    border-bottom: 1px solid #ddd;
}

.maxHeightAuto {
    max-height: unset !important;
    overflow: unset !important;
}

/*---------------------------------------------------------------Buttons------------------------------------------------*/
.ui.button {
    font-weight: normal;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.ui.primary.buttons .button, .ui.primary.button {
    background-color: #166b83 !important;
}

.ui.primary.button:hover {
    background-color: #0e5468 !important;
}

.ui.secondary.buttons .button, .ui.secondary.button {
    background: #8ea8b2 !important;
}

.ui.secondary.buttons .button:hover, .ui.secondary.button:hover {
    background: #7e9ca7 !important
}

button:focus {
    outline: none !important;
}

/*---------------------------------------------------------------Heading------------------------------------------------*/
h2, .headingMain {
    font-family: 'SegoeUI-Semibold', sans-serif !important;
    font-size: 22px;
    font-weight: normal;
}

h3 {
    font-family: 'SegoeUI-Semibold', sans-serif;
    font-size: 22px;
}

h5 {
    font-family: 'SegoeUI-Semibold', sans-serif;
    font-size: 16px;
}

.headingMain {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px !important;
}

/*---------------------------------------------------------------Login------------------------------------------------*/
.login {
    width: 100%;
    height: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    text-align: center;
}

.login h1 {
    font-size: 28px !important;
    font-weight: lighter !important;
    color: #104e61 !important;
}

.loginLogo {
    margin-bottom: 80px;
}

.login .google {
    font-size: 16px !important;
    padding: 0 20px !important;
    width: 260px;
}

.contentCenter {
    width: 100%;
}

.login {
    height: 100vh;
    background: url("../images/loginLeftBg.jpg") no-repeat;
    background-size: cover;
    background-position: 100%;
}

.login button.google {
    margin: 30px 0 90px;
    border-radius: 100px !important;
    overflow: hidden;
    font-size: 17px !important;
    font-weight: bold !important;
    padding: 5px 15px !important;
    box-shadow: 0px 2px 10px #ccc !important;
}

@media (max-width: 1400px) {
    .login .ui.grid > .row > [class*="fourteen wide computer"].column.customGrid {
        width: 70% !important;
    }

    .loginLogo {
        padding-top: 80px;
    }

    .login button.google {
        margin: 30px 0 50px;
    }

    .ui.table.tableStyle.tableOpenSurgery .first-th {
        width: 170px !important;
        max-width: 170px !important;
    }
}

@media (max-width: 991px) {
    .login .bgShape {
        /* background: url("../images/bgShapeMobile.png") no-repeat; */
        background-size: contain;
    }

    .login .ui.grid > .row > [class*="fourteen wide computer"].column.customGrid {
        width: 100% !important;
    }

    .loginLogo {
        padding-top: 160px;
    }

    .ui.pointing.secondary.menu .item {
        padding: 20px 30px !important;
    }

    .landing {
        background-position: center bottom;
        background-size: contain;
    }

    .landing > .ui.grid {
        margin-top: 30px;
    }
}

#root, #root > div {
    height: 100%;
}


/*---------------------------------------------------------------Login With Form------------------------------------------------*/
.loginForm, .bgShape {
    height: 100%;
    float: left;
    width: 100%;
}

.loginForm {
    background-image: url('../images/bgTexture.jpg');
}

.loginForm .bgShape {
    background: url("../images/bgShape.png") no-repeat;
    background-size: contain;
}

.loginForm .textCenter {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.loginForm .ui.label {
    background: transparent;
    font-size: 20px;
    font-weight: normal;
    color: #323232;
}

.loginForm .loginFormLogo {
    padding-top: 100px;
}

.loginForm .login-form {
    margin-top: 100px !important;
}


/*-------------------------------------------------------------------Header-----------------------------------------*/
.logo {
    width: 200px;
    padding: 38px 0;

}

.cdp-udr .ui.basic.segment > .ui.secondary.menu {
    background: #fff;
    height: 60px;
    color: #273140;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0px 2px 8px #ddd;
    margin: 0;
    width: 100%;
}

.cdp-udr .ui.secondary.menu .item {
    color: #273140;
    padding: 21px 25px;
    margin: 0;
}

.cdp-udr .ui.secondary.menu .item.toggle {
    padding: 20px 22px;
    border-radius: 0;
    background: #fff;
}

.cdp-udr .ui.secondary.menu .item.toggle:hover {
    background: #ebeef1;
}

.cdp-udr .ui.secondary.menu .item.toggle .icon {
    font-size: 20px;
    color: #d16f26;
}

.cdp-udr .ui.secondary.menu .item:hover {
    color: #273140 !important;
}

.cdp-udr .ui.secondary.menu .icon.setting {
    width: auto;
}

.cdp-udr .ui.secondary.menu .ui.dropdown:hover {
    background: #fff;
}

.cdp-udr .ui.secondary.menu .ui.dropdown .menu {
    border-radius: 0 0 4px 4px !important;
    left: -120px !important;
    margin-top: 0 !important;
    width: 185px;
}

.cdp-udr .ui.secondary.menu .ui.dropdown .menu .menu {
    margin-top: 35px !important;
}

.cdp-udr .ui.secondary.menu .ui.dropdown .menu .item {
    border-radius: 0;
}

.cdp-udr .ui.secondary.menu .menu .item.innerDropdown {
    padding: 0 !important;
}

.cdp-udr .ui.secondary.menu .menu .item.innerDropdown .menu {
    font-size: .9rem !important;
}

.cdp-udr .ui.secondary.menu .item.innerDropdown > div:first-child {
    padding: 0.78571429em 1.14285714em !important;
    width: 100%;
}

.cdp-udr .ui.secondary.menu .ui.dropdown .menu .item + .item {
    border-top: 1px solid #e1e1e1;
}

.cdp-udr .ui.secondary.menu .dropdown.user {
    padding: 17px 16px;
}

.cdp-udr .ui.secondary.menu .dropdown.user .text {
    margin-top: 4px;
}

.cdp-udr .ui.secondary.menu .ui.floating.label {
    top: 7px !important;
    left: 52px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    padding: 4px !important;
}

.cdp-udr .ui.secondary.menu .ui.compact.menu {
    background: none;
    border: none;
    box-shadow: none;
}

.cdp-udr .ui.menu .right.menu .dropdown:last-child .menu {
    right: 5px !important;
}

/*-----------------------------------------------------------List------------------------------------------------*/
.ui.horizontal.list.topValues > .item {
    margin-left: 50px;
    padding: 0 !important;
}

.ui.horizontal.list.topValues > .item span {
    cursor: pointer;
}

.ui.horizontal.list.topValues > .item span:hover {
    color: #0e5468;
}

/*-----------------------------------------------------------Calendar------------------------------------------------*/
.calendar-container .rbc-calendar {
    min-height: 700px
}

.rbc-calendar .rbc-header {
    font-size: 15px;
    text-transform: uppercase;
    background: #fafafa;
    padding: 13px 4px !important;
}

.rbc-calendar .rbc-header > a {
    min-height: 52px;
    display: block;
}

.rbc-calendar .rbc-month-view {
    border-radius: 4px;
}

.rbc-calendar .rbc-off-range-bg {
    background: #fafafa;
}

.rbc-calendar .rbc-date-cell {
    text-align: left;
    padding-left: 5px;
}

.rbc-calendar .rbc-date-cell a {
    font-family: 'SegoeUI-Semibold', sans-serif;
}

.rbc-calendar .rbc-toolbar .rbc-toolbar-label {
    font-family: 'SegoeUI-Semibold', sans-serif;
    font-size: 22px;
    margin-left: 15px;
    text-align: left;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group button {
    cursor: pointer;
    font-size: 14px;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button + button {
    float: left;
    padding: 0.78571429em 0.78571429em 0.78571429em;
    font-size: 0;
    height: 38px;
    width: 38px;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button + button:after {
    content: '';
    font-family: Icons;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    font-size: 18px !important;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button:first-child {
    border-radius: 4px;
    margin-left: 10px;
    font-size: 14px;
    line-height: 1rem;
    padding: 12px 20px;
    background-color: #166b83 !important;
    color: #fff;
    border: none;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button:first-child:hover {
    background-color: #0e5468 !important;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button:nth-child(2) {
    border-radius: 4px 0 0 4px;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button:nth-child(2):after {
    content: '';
}

.rbc-calendar .rbc-toolbar {
    margin-bottom: 15px;
}

.rbc-calendar .rbc-toolbar button:active:hover,
.rbc-calendar .rbc-toolbar button.rbc-active:hover,
.rbc-calendar .rbc-toolbar button:active:focus,
.rbc-calendar .rbc-toolbar button.rbc-active:focus {
    background-color: #0e5468 !important;
    color: #fff;
    outline: none;
    border-color: #0e5468;
}

.rbc-calendar .rbc-toolbar button:active, .rbc-calendar .rbc-toolbar button.rbc-active {
    background-color: #166b83 !important;
    color: #fff;
    box-shadow: none;
    border-color: #166b83;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:last-child button {
    font-size: 14px;
    line-height: 1rem;
    padding: 11px 15px
}

.rbc-calendar .rbc-event {
    border-radius: 0 !important;
    background-color: #f7fafb;
    color: #000;
    font-size: 11px !important;
}

.rbc-month-row {
    overflow: unset !important;
}

.rbc-calendar .rbc-event .rbc-event-content {
    font-family: 'SegoeUI-Semibold', sans-serif;
    overflow-x: auto;
    overflow: unset;
    position: relative;
}

.rbc-row-segment .rbc-event-content {
    white-space: unset !important;
}

.rbc-row-segment .rbc-event-content .notes {
    float: right;
}

.rbc-calendar .rbc-event .rbc-event-content span {
    font-family: 'SegoeUI-Regular', sans-serif;
}

.rbc-agenda-view > .rbc-agenda-table {
    border-bottom: none !important;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    border-bottom: none !important;
    padding-left: 5px !important;
}

.item:has(span.test) {
    background: #ff0000;
}

/*----------------------------------------------topFilters-------------------------------------------------------*/
.topFilters {
    float: right;
}

.claimMenu .ui.pointing.menu {
    width: 400px;
}

.claimMenu .ui.pointing.menu .item {
    width: 20%;
    text-align: center;
    display: inline-block;
}

.claimMenu .ui.pointing.menu .item.active {
    background: #166b83;
    color: #fff;
}

.claimMenu .ui.pointing.menu .item.active:after {
    background: #166b83;
}

/*-----------------------------------------------------------Tabs------------------------------------------------*/
.ui.pointing.secondary.menu {
    background: transparent;
    box-shadow: none;
    border-bottom: none !important;
    margin-bottom: 20px;
    border-radius: 4px 4px 0 0;
    z-index: 1;
}

.ui.pointing.secondary.menu .item {
    padding: 20px 60px !important;
    background: #fff !important;
    border-bottom: 3px solid transparent !important;
}

.ui.pointing.secondary.menu .item:hover {
    background: #fff !important;
    color: #000;
}

.ui.pointing.secondary.menu .item:hover .icon {
    opacity: 1;
}

.ui.pointing.secondary.menu .item.active,
.ui.secondary.pointing.menu .active.item:hover {
    border-bottom: 3px solid #ce6429 !important;
    background: #fff !important;
}

.ui.pointing.secondary.menu .item:first-child {
    border-radius: 4px 0 0 0;
}

.ui.pointing.secondary.menu .item:last-child {
    border-radius: 0 4px 0 0;
}

.ui.pointing.secondary.menu .item + .item,
.ui.pointing.secondary.menu .item + .item.active:hover {
    border-left: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.ui.pointing.secondary.menu .item .icon {
    color: #ce6429;
    opacity: 0.7;
    margin-right: 12px;
}

.ui.pointing.secondary.menu .item.active .icon {
    color: #ce6429;
    opacity: 1;
}

.cdp-udr .ui.secondary.menu .logoSmall {
    margin: 8px 0;
    display: none;
}

.ui.segment.tabContent {
    background: none;
    padding-right: 0 !important;
    position: static !important;
}

/*-----------------------------------------------------------sidebar------------------------------------------------*/
.containerMain {
    padding: 30px 20px 20px;
    overflow: auto;
    height: calc(100% - 60px);
}

.cdp-udr .ui.segment.pushable {
    float: left;
    width: 100%;
    height: 100%;
    /*padding-top: 60px !important;*/
    z-index: 1;
}

.ui.left.sidebar, .ui.right.sidebar {
    -webkit-box-shadow: 0px 0px 20px rgba(34, 36, 38, 0.15);
    box-shadow: 0px 0px 20px rgba(34, 36, 38, 0.15);
}

.cdp-udr .ui.visible.thin.left.sidebar ~ .fixed, .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher {
    transform: translate3d(300px, 0px, 0px);
}

.cdp-udr .ui.thin.left.sidebar ~ .fixed, .cdp-udr .ui.thin.left.sidebar ~ .pusher {
    transform: translate3d(66px, 0px, 0px);
}

.cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment {
    padding-right: 300px;
    transition: all 0.66s ease 0s;
    -webkit-transition: all 0.66s ease 0s;
}

.cdp-udr .ui.left.push.sidebar {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
}

.cdp-udr .ui.visible.push.sidebar {
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.cdp-udr .ui.basic.segment {
    border-radius: 0;
    border: none;
    box-shadow: none;
    padding: 0 66px 0 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.cdp-udr .ui.thin.left.sidebar.visible {
    width: 300px;
    background: #fff;
}

.cdp-udr .ui.thin.left.sidebar {
    width: 66px;
    background: #fff;
    overflow-x: hidden;
}

.cdp-udr .ui.thin.left.sidebar::-webkit-scrollbar {
    display: none;
}

.cdp-udr .ui.thin.left.sidebar.visible .logo {
    width: 200px;
    padding: 35px 0;
}

.cdp-udr .ui.thin.left.sidebar .logo {
    width: 210px;
    padding: 38px 0 38px 7px;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu {
    background: none;
    width: 100%;
    border: none;
    box-shadow: none;
}

.cdp-udr .ui.thin.left.sidebar.visible .vertical.menu .item {
    font-family: 'SegoeUI-Regular', sans-serif !important;
    font-size: 14px;
    color: #262626;
    text-transform: uppercase;
    text-align: left;
    padding: 20px;
}

.cdp-udr .ui.thin.left.sidebar.visible .vertical.menu .item .item {
    text-transform: capitalize;
    border-left: 1px solid #E6B390;
    margin-left: 5px;
    padding: 15px 20px;
}

.cdp-udr .ui.thin.left.sidebar.visible .vertical.menu .item .item.active {
    background-color: #e6b288;
    border-left: 1px solid #D06F2A;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item {
    font-family: 'SegoeUI-Regular', sans-serif !important;
    font-size: 0;
    color: #262626;
    text-transform: uppercase;
    text-align: right;
    padding: 20px;
}

.cdp-udr .ui.thin.left.sidebar.visible .vertical.menu .item i.icon {
    font-size: 16px !important;
    padding-right: 15px;
    display: inline;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item i.icon {
    font-size: 16px !important;
    padding-right: 10px;
    display: inline;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item i.icon.dropdown {
    padding-right: 0;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item .title {
    display: inline !important;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item .label {
    height: 22px;
    background: #d67d38;
    padding: 5px 7px;
    border-radius: 11px;
    min-width: 22px;
    margin-top: -5px;
}

.ui.form.formStyle label {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #a7bbc3 !important;
    text-transform: uppercase !important;
}

h3 {
    font-weight: normal !important;
    font-size: 17px !important;
    color: #e47a2f;
}

/*.ui.thin.left.sidebar .vertical.menu .item:last-child {*/
/*border-bottom: 1px solid #525c69;*/
/*}*/
/*.cdp-udr .ui.thin.left.sidebar .vertical.menu .item:not(.active):hover {*/
/*background: #ebeef1;*/
/*padding-left: 25px;*/
/*}*/
/*.cdp-udr .ui.thin.left.sidebar .vertical.menu .item.active {*/
/*background: #eeceaf;*/
/*border-left: 5px solid #ce6429;*/
/*color: #000 !important;*/
/*}*/
.cdp-udr .pushable > .pusher {
    overflow: auto;
    background: #ebeef1;
}

.cdp-udr .leftMenu.ui.accordion > .title {
    float: left;
    width: 100% !important;
    padding: 0;
}

.ui.accordion.leftMenu .item a {
    color: #fff !important;
    width: 100% !important;
}

.ui.accordion.leftMenu div.list a a svg {
    margin-right: 5px !important;
}

.cdp-udr .leftMenu.ui.accordion > a,
.cdp-udr .leftMenu.ui.accordion > .title span {
    float: left;
    width: 100%;
    color: #232323;
    text-transform: uppercase;
    text-align: left;
}

.cdp-udr .leftMenu.ui.accordion > a {
    font-size: 0;
    text-align: center;
    padding: 15px 15px 20px 15px;
}

.cdp-udr .ui.sidebar.visible .logo {

}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > a {
    transition: all 0.3s ease-in-out !important;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > a,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title span {
    padding: 16px 15px;
}

.cdp-udr .leftMenu.ui.accordion > .title span {
    font-size: 0;
    text-align: left;
    padding: 15px 5px 20px 22px;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > a,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title span {
    font-size: 14px;
    text-align: left;
}

.cdp-udr .leftMenu.ui.accordion > a:hover,
.cdp-udr .leftMenu.ui.accordion > .title span:hover {
    text-decoration: none;
    background: #ebeef1;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > a:hover,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title span:hover {
    padding-left: 20px;
}

.cdp-udr .leftMenu.ui.accordion > a.active a,
.cdp-udr .leftMenu.ui.accordion > .title.active span {
    color: #000;
}

.cdp-udr .leftMenu.ui.accordion > .title.active span i.icon.angle.down {
    transform: rotate(180deg);
    margin-top: 8px !important;
}

.cdp-udr .leftMenu.ui.accordion > a.active:hover,
.cdp-udr .leftMenu.ui.accordion > .title.active span:hover {
    background: #eeceaf;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title.active span:hover,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > a.active:hover {
    padding-left: 15px;
}

.cdp-udr .leftMenu.ui.accordion > a > .icon,
.cdp-udr .leftMenu.ui.accordion > .title .icon {
    margin-right: 0;
    font-size: 16px;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > a > .icon,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title .icon {
    margin-right: 15px;
    font-size: 16px;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title .icon.angle {
    font-size: 14px;
    margin-top: 0;
    float: right;
    margin-right: 0 !important;
    transition: all 0.3s ease-in-out !important;
}

.cdp-udr .leftMenu.ui.accordion > .title .icon.angle.down {
    font-size: 12px;
    margin-top: 4px;
    float: right;
    margin-right: 0 !important;
    transition: all 0.3s ease-in-out !important;
}

.cdp-udr .leftMenu.ui.accordion > .title > .icon.dropdown {
    display: none;
}

.cdp-udr .leftMenu.ui.accordion > .title.active,
.cdp-udr .leftMenu.ui.accordion > a.active {
    border-left: 5px solid #ce6429;
    background: #eeceaf;
}

.cdp-udr .leftMenu.ui.accordion > .content {
    width: 100%;
    float: left;
    padding: 0 !important;
}

.cdp-udr .leftMenu.ui.accordion > .content .accordion {
    width: 100%;
    float: left;
    margin-top: 0;
    background: #e6b288;
}

.cdp-udr .leftMenu.ui.accordion > .content .accordion .title {
    float: left;
    width: 100%;
    text-align: left;
    padding: 0;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .accordion .title span {
    width: 100%;
    float: left;
    padding: 14px 15px 14px 25px;
}

.cdp-udr .leftMenu.ui.accordion > .content .accordion .title span {
    width: 100%;
    float: left;
    padding: 14px 0 14px 25px;
}

.cdp-udr .leftMenu.ui.accordion > .content .accordion .title span .icon.angle {
    float: right;
}

.cdp-udr .leftMenu.ui.accordion > .content .accordion .title span .icon {
    float: left;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .accordion .title span.titleText,
.cdp-udr .leftMenu.ui.accordion > .content .accordion .title span.titleText {
    width: auto;
    padding: 0;
}

.cdp-udr .leftMenu.ui.accordion > .content .accordion .title .icon.dropdown {
    display: none;
}

.cdp-udr .leftMenu.ui.accordion > .content .title.active {
    background: #d59c6e;

}

.cdp-udr .leftMenu.ui.accordion > .content .title:hover {
    background: #d59c6e;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion .accordion .content .ui.list {
    background: #d59c6e;
    margin-top: -7px;
    padding-left: 35px;
}

.ui.accordion.leftMenu .title:nth-child(4) {
    padding: 14px 0 12px 15px;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .ui.list {
    float: left;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0;
}


.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item a {
    float: left;
    color: #232323 !important;
    text-transform: capitalize;
    text-align: left;
    padding: 13px 15px 13px 25px;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item:hover {
    background: #d59c6e;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .ui.list .item,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .accordion .title .titleText {
    font-size: 14px;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .accordion .title .titleText {
    margin-left: 6px !important;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item,
.cdp-udr .leftMenu.ui.accordion > .content .accordion .title .titleText {
    font-size: 0;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item > .icon {
    margin-right: 10px;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item:after {
    display: none;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item {
    float: left;
    width: 100%;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item.active {
    background-color: #d59c6e;
    color: #000 !important;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item .icon {
    font-size: 15px !important;
    color: #232323;
    line-height: 18px;
    margin: 0 auto !important;
    padding-left: 0;
    float: none;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .ui.list .item .icon {
    float: left;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .ui.list .item .icon {
    line-height: normal;
    margin-right: 10px !important;

}

.cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .btnFeedback {
    right: 300px;
}

.cdp-udr .ui.visible.push.sidebar {
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment .btnFeedback {
    right: 300px;
}

.cdp-udr .leftMenu.ui.accordion > a > .icon.caredelta {
    background: url("../images/careDelta.png") no-repeat;;
}

.cdp-udr .leftMenu.ui.accordion > a > .icon.commonBond {
    background: url("../images/commonBond.png") no-repeat;;
}

.icon.iconDentist {
    background: url("../images/iconDentistOutline.png") no-repeat;
}

/*-------------------------------------------------------------Page-Title-------------------------------------------*/
/*.cdp-udr .ui.huge.header {*/
/*height: 50px;*/
/*font-family: 'BentonSans-Bold';*/
/*font-size: 20px;*/
/*padding-top: 14px;*/
/*border-bottom: 1px dashed #9f9f9f;*/
/*font-weight: normal;*/
/*-webkit-box-shadow: inset 0px 3px 6px -1px rgba(62, 62, 62, 0.8);*/
/*-moz-box-shadow: inset 0px 3px 6px -1px rgba(62, 62, 62, 0.8);*/
/*box-shadow: inset 0px 3px 6px -1px rgba(62, 62, 62, 0.8);*/
/*color: #273140;*/
/*}*/

/*.cdp-udr .ui.huge.header span {*/
/*float: left;*/
/*}*/

/*.cdp-udr .ui.huge.header span:first-child {*/
/*text-transform: uppercase;*/
/*}*/

/*.cdp-udr .ui.huge.header span + span {*/
/*font-family: 'SegoeUI-Bold';*/
/*font-size: 18px;*/
/*}*/

/*.cdp-udr .ui.huge.header.bgColor-Yellow span:nth-child(3) {*/
/*display: none;*/
/*}*/

/*.bgColor-Red {*/
/*background: #ffb0a8;*/
/*}*/

/*.bgColor-Blue {*/
/*background: #a8eaff;*/
/*}*/

/*.bgColor-Yellow {*/
/*background: #fee997;*/
/*}*/

/*.bgColor-Green {*/
/*background: #c5e1a5;*/
/*}*/

/*-------------------------------------------------------------------Table-----------------------------------------*/

.table.tableStyle {
    border-radius: 0;
    border: none;
    font-size: 13px;
    margin-top: 15px;
}

.table.tableStyle td {
    border-top: none !important;
    padding: 15px 0.75rem;
}

.table.tableStyle thead th {
    font-family: 'SegoeUI-Semibold', sans-serif;
    border-top: none;
    color: #000 !important;
    background: #EECEAF !important;
    border-radius: 0 !important;
    border-bottom: 3px solid #EECEAF !important;
    vertical-align: middle !important;
    padding: 2px 0.75rem;
}

.table.tableStyle thead th > div {
    min-height: 47px;
    align-items: center;
    display: flex;
    align-content: center;
}

.table.tableStyle thead th.active {
    border-bottom: 3px solid #ce6429 !important;
}

.table.tableStyle thead i.icon {
    color: #ce6429;
    opacity: 0.6;
    margin-left: 15px;
}

.table.tableStyle thead th.active i.icon {
    opacity: 1;
}

.table.tableStyle thead th:hover i.icon {
    color: #ce6429;
    opacity: 1;
}

.table.tableStyle tbody tr:hover {
    background: #fafcfd;
    color: #000;
}

.tr-reduce {
    height: 100px;
    overflow: hidden;
}

.tr-expand {
    height: auto;
}

.tableStyle.table-striped tbody tr:nth-of-type(even) {
    background-color: #fff;
}

.tableStyle.table-striped tbody tr:nth-of-type(odd) {
    background-color: #FCF5EF !important;
}

.tableStyle.table-striped tbody tr:nth-of-type(odd):hover {
    background-color: #f9eadd !important;
}

/*.table thead th a {*/
/*color: #273140;*/
/*display: block;*/
/*padding: 15px 0 15px 10px;*/
/*border-bottom: 3px solid transparent;*/
/*}*/
/*.table thead th a:focus {*/
/*color: #d67d38;*/
/*border-bottom: 3px solid #d67d38;*/
/*text-decoration: none;*/
/*}*/
/*.table thead th a:focus i.icon {*/
/*color: #d67d38;*/
/*}*/
/*.table thead th:last-child a {*/
/*padding: 15px 10px;*/
/*}*/

.table tbody tr:hover {
    background: #f8fafb;
    color: #000;
}

.table tfoot td {
    background: none !important;
    border-bottom: 1px solid #aaacb0;
    border-top: 1px solid #aaacb0;
    border-radius: 0 !important;
}

.table .ui.button {
    padding: 8px 20px;
}

/*.table.table-border thead th:first-child {*/
/*padding-left: 18px;*/
/*}*/

/*.table.table-border tbody tr {*/
/*border-left: 36px solid transparent;*/
/*}*/

/*.table.table-border tbody tr.border-green {*/
/*border-left: 36px solid #c5e1a5;*/
/*}*/

/*.table.table-border tbody tr.border-yellow {*/
/*border-left: 36px solid #fee997;*/
/*}*/

/*.table.table-border tbody tr.border-red {*/
/*border-left: 36px solid #ffb0a8;*/
/*}*/

.table.tableStyle th {
    padding-left: 10px;
}

.table.fc-table, .table.fc-table td {
    border: 1px solid #DDDDDD;
}

.table.fc-table td {
    padding: 6px 0.75rem;
}

/*---------------------------------------Table--------------------------------------*/
.tableResponsive {
    overflow-x: auto;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.ui.striped.table.customTable {
    border-radius: 0;
    border: none;
}

.ui.striped.table.customTable thead th {
    font-family: 'SegoeUI-Semibold', sans-serif !important;
    font-weight: normal !important;
    border-radius: 0 !important;
    border-bottom: none;
    background: #d1e0e4;
    border-bottom: 3px solid #d1e0e4 !important;
    border-left: none !important;
    vertical-align: middle !important;
    padding: 2px 0.75rem;
    color: #176279;
    border-left: 5px solid #d1e0e4;
}

.ui.striped.table.customTable thead tr {
    background: #d1e0e4;
}


.ui.striped.table.customTable thead th.sorted,
.ui.striped.table.customTable thead th:hover {
    background: #d1e0e4 !important;
    color: #176279 !important;
}

.ui.striped.table.customTable thead th > div {
    min-height: 43px;
    align-items: center;
    display: flex;
    align-content: center;
}

.ui.striped.table.customTable thead th.sorted {
    border-bottom: 3px solid #176279 !important;
}

.ui.striped.table.customTable thead i.icon {
    color: #176279;
    opacity: 0.6;
    margin-left: 4px;
}

.ui.striped.table.customTable thead th.active i.icon {
    opacity: 1;
}

.ui.striped.table.customTable thead th:hover i.icon {
    color: #176279;
    opacity: 1;
}


.ui.striped.table.customTable tr td {
    border-top: none;
    color: #176279;
}

.ui.striped.table.customTable > tr:nth-child(1n),
.ui.striped.table.customTable tbody tr:nth-child(1n) {
    background: #edf3f4;
}

.ui.striped.table.customTable > tr:nth-child(2n),
.ui.striped.table.customTable tbody tr:nth-child(2n) {
    background: #fff;
}

/*--------------------------------------------------------------------------------------  */
.TableHeaderFixed thead th, .TableHeaderFixed {
    position: sticky;
    top: 0;
    z-index: 10;
}

.ui.pagination.menu {
    box-shadow: none;
    border: none;
}

.ui.pagination.menu .item {
    border-radius: 4px !important;
    border: 1px solid #dededf;
    color: #d67d38;
}

.ui.pagination.menu .item:before {
    content: "";
}

.ui.pagination.menu .item:hover {
    background: #d67d38;
    color: #fff;
}

.ui.pagination.menu .item .item.active {
    background: #d67d38;
    color: #fff;
}

.ui.pagination.menu .item + .item {
    margin-left: -1px;
}

/*.head-pagination {*/
/*margin: 0;*/
/*}*/

/*.head-pagination li {*/
/*float: left;*/
/*}*/

/*.head-pagination li .ui.button {*/
/*background: #fff;*/
/*border: 1px solid #dededf;*/
/*width: 37px;*/
/*height: 37px;*/
/*padding: 0;*/
/*}*/

/*.head-pagination li p {*/
/*font-size: 13px;*/
/*margin-top: 9px;*/
/*}*/

/*.head-pagination li + li .ui.button {*/
/*margin-left: -1px;*/
/*}*/

/*-----------------------------------------------------------Modal--------------------------------------------------*/
.ui.inverted.dimmer .ui.loader:after {
    border-color: #FFFFFF transparent transparent !important;
}

.ui.inverted.dimmer .ui.loader {
    color: #fff !important;
}

.ui.modal {
    bottom: auto;
    font-size: 13px !important;
}

.ui.modal .header {
    color: #262626;
    font-family: 'SegoeUI-Semibold', sans-serif !important;
    font-size: 20px !important;
    padding: 18px 20px !important;
    border-color: #e1e1e1;
    border-radius: 4px 4px 0 0;
    font-weight: normal !important;
}

.ui.modal .close {
    position: static !important;
    color: #273140 !important;
    margin: 12px 13px 11px 0 !important;
    padding: 7px !important;
    float: right;
}

.ui.large.modal.transition.visible.active .close {
    color: #273140 !important;
}

.ui.modal .content {
    max-height: calc(100% - 135px);
}

.ui.modal.familyView > .content {
    padding: 1.5rem .5rem !important;
}

.ui.modal.familyView .actions {
    display: none;
}

.ui.modal .content .content-middle {
    padding: 40px 20px;
}

.ui.modal .actions {
    padding: 12px 20px;
    background: #fff;
    border-top: 1px solid #e1e1e1;
    border-radius: 0 4px 4px;
    float: left;
    width: 100%;
}

.ui.modal .actions .button {
    margin-bottom: 0;
}

.ui.modal .formStyle label {
    text-transform: uppercase;
    font-size: 12px !important;
    font-weight: 700;
    margin-bottom: 5px;
    display: block;
}

.ui.mini.modal {
    width: 400px !important;
}

.ui.mini.modal label {
    min-width: 30%;
}

.ui.mini.modal .ui.input, .ui.mini.modal .ui.form .inline.field > .selection.dropdown {
    width: 66% !important;
}

.ui.mini.modal .inline.field .field {
    width: 66%;
    display: inline-block;
}

.ui.mini.modal .inline.field .field .ui.input {
    width: 100% !important;
}

.ui.mini.modal .inline.field .react-datepicker__input-container {
    width: 66%;
}


/*----------------------------------------------------------Card--------------------------------------------------*/
.cdp-udr .ui.card {
    width: 100%;
    box-shadow: none;
    margin-bottom: 20px !important;
    margin-top: 0 !important;
    border-radius: 4px;
}

.cdp-udr .ui.card .content {
    padding: 15px;
}

.cdp-udr .ui.card .content .header {
    font-size: 18px !important;
    color: #273140;
    font-weight: normal !important;
}

.cdp-udr .ui.card .content.pb0 {
    padding-bottom: 0 !important;
}

@media (max-width: 575px) {
    .header-os {
        width: 5% !important;
    }

    .header-os-inner {
        width: 10% !important;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }

    .landing {
        background-position: center bottom;
    }


    .topFilters {
        width: 100%;
        float: right;
        text-align: right;
    }

    .topFilters .filter {
        text-align: left;
    }

    .topFilters .ui.dropdown,
    .topFilters .ui.input,
    .topFilters .filter,
    .topFilters .datePicker,
    .topFilters .datePicker input{
        width: 100%;
    }

    .topFilters .ui.button {
        float: right;
        margin-right: 0 !important;
    }

    .topFilters div.float-right {
        text-align: right;
    }

    .datePicker .react-datepicker-wrapper {
        width: 100%;
    }

    .claimMenu .ui.pointing.menu {
        width: 100%;
    }
}


/*------------------------------------------------------Feedback-btn------------------------------------------------*/
.btnFeedback {
    width: 30px;
    height: 100px !important;
    background-color: #d16f26;
    border-radius: 10px 0 0 10px;
    z-index: 999;
    box-shadow: 0px 0px 15px rgba(68, 68, 68, 0.4) !important;
    text-align: center;
    color: #222;
    padding: 18px 0;
    position: fixed;
    right: 66px;
    cursor: pointer;
    top: 50%;
}

.btnFeedback .icon {
    font-size: 20px !important;
    color: #fff !important;
    margin-right: 0 !important;
}

.btnFeedback small {
    font-size: 13px;
    color: #fff;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    letter-spacing: 0.5px;
}

.btnFeedback:hover {
    width: 40px;
    background-color: #c4631b;
}

/*-----------------------------------------------------------datePicker------------------------------------------------*/
.datePicker {
    display: inline-block;
    position: relative;
}

.datePicker .react-datepicker {
    box-shadow: 0px 0px 12px #bbb;
    border: none !important;
}

.datePicker input {
    border: 1px solid #dededf;
    border-radius: 4px;
    padding: 0.67857143em 2.1em 0.67857143em 1em;
    height: 38px;
    outline: none;
}

.datePicker .icon.calendar {
    position: absolute;
    right: 10px;
    top: 9px;
}

.datePicker .react-datepicker__day--keyboard-selected {
    background-color: #d16f26 !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

/*.cdp-udr .ui.card .content .header.medium {*/
/*font-family: 'SegoeUI-Regular';*/
/*font-size: 17px !important;*/
/*color: #273140;*/
/*margin: 0 0 8px 0;*/
/*}*/

/*.cdp-udr .ui.card .content.header-style {*/
/*padding: 11px 20px;*/
/*}*/

/*.cdp-udr .ui.card .content.header-style .header {*/
/*line-height: 35px;*/
/*margin-top: 0 !important;*/
/*}*/

/*.cdp-udr .ui.card .content.header-style .field .text {*/
/*font-size: 1rem !important;*/
/*font-family: 'SegoeUI-Regular';*/
/*}*/

/*.cdp-udr .ui.card .content.header-style .ui.fluid.selection.dropdown {*/
/*min-height: 37px;*/
/*padding: 10px 12px !important;*/
/*width: 240px;*/
/*}*/

.cdp-udr .ui.card .actions {
    padding: 12px 20px;
    min-height: 62px;
    background: #f2f2f2;
    border-top: 1px solid #e1e1e1;
    border-radius: 0 4px 4px;
}

.cdp-udr .ui.card .actions label {
    margin-bottom: 0;
    font-weight: bold;
}

.cdp-udr .ui.card .extra {
    border-top: 1px solid #e1e1e1 !important;
}

.cdp-udr .ui.card.patientDetails label {
    font-size: 13px !important;
    margin-bottom: 2px;
}

.cdp-udr .ui.card.patientDetails h4 {
    font-family: 'SegoeUI-Bold', sans-serif;
    font-size: 18px;
    color: #00838f;
    margin-top: 0;
}

.cdp-udr .ui.card.patientDetails .icon.user.circle {
    color: #00838f;
}

.cdp-udr .ui.card.patientDetails .ui.fitted.divider {
    margin: 24px 0;
}

.cdp-udr .ui.card.patientDetails .row:nth-child(n+2) .col-6:first-child, .cdp-udr .ui.card.patientDetails .row:nth-child(n+2) .col-8 {
    padding-left: 90px;
}

.cdp-udr .ui.card.patientDetails .row .col-6:nth-child(n+2) {
    border-left: 1px solid #e1e1e1;
    padding-left: 20px;
}

.cdp-udr .ui.card.patientDetails .row .col-4:nth-child(n+2) {
    border-left: 1px solid #e1e1e1;
    padding-left: 20px;
}

/*.cdp-udr .ui.card.attachments .thumbnail {*/
/*margin-bottom: 20px;*/
/*border-radius: 4px;*/
/*overflow: hidden;*/
/*position: relative;*/
/*height: 176px;*/
/*text-align: center;*/
/*border: 1px solid #e1e1e1;*/
/*align-items: center;*/
/*display: flex;*/
/*}*/

/*.cdp-udr .ui.card.attachments .thumbnail:hover .overlay {*/
/*display: block;*/
/*}*/

/*.cdp-udr .ui.card.attachments .thumbnail img {*/
/*margin: 0 auto;*/
/*}*/

/*.cdp-udr .ui.card.attachments .thumbnail.preview {*/
/*border: none;*/
/*border-radius: 0 0 4px 4px;*/
/*height: auto;*/
/*}*/

/*.cdp-udr .ui.card.attachments .overlay {*/
/*background: rgba(0, 0, 0, 0.7);*/
/*width: 100%;*/
/*height: 100%;*/
/*position: absolute;*/
/*z-index: 1;*/
/*display: none;*/
/*}*/

.cdp-udr .ui.card.mb0 {
    margin-bottom: 0 !important;
}

/*.ui.card.attachments .overlay a {*/
/*color: #fff;*/
/*position: relative;*/
/*top: 44%;*/
/*}*/

/*.ui.card.attachments.Patients .row .col-sm-6 {*/
/*max-width: 33.33% !important;*/
/*flex: auto !important;*/
/*}*/

/*.ui.card.attachments.Patients .thumbnail {*/
/*height: 100px !important;*/
/*}*/

/*-----------------------------------------------------------scheduler------------------------------------------------*/
.rbc-day-slot .rbc-event-label {
    visibility: hidden;
}

/*-----------------------------------------------------------Others------------------------------------------------*/
.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before, .ui.toggle.checkbox input ~ .box:after, .ui.toggle.checkbox input ~ label:after {
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.ui.toggle.checkbox input:checked ~ .box:before, .ui.toggle.checkbox input:checked ~ label:before {
    border: none !important;
}

/*.field.mt-3 {*/
/*margin: 3px;*/
/*}*/

/*.thumbnail-image {*/
/*align-items: center;*/
/*border: 1px solid #e1e1e1;*/
/*border-radius: 4px;*/
/*display: flex;*/
/*height: 176px;*/
/*margin: 20px;*/
/*overflow: hidden;*/
/*position: relative;*/
/*text-align: center;*/
/*}*/

.ui.text.loader {
    padding-top: 20px !important;
}

.fc-calander .rbc-calendar .rbc-event {
    background: none !important;
}

.rbc-time-content {
    border-top: 1px solid #ddd !important;
}

.card .card-header {
    padding: 15px;
    border-bottom: 1px solid #dadad9;
    border-radius: 4px 4px 0 0;
    background: #fff;
}

h3.headingSub {
    /*font-family: 'BentonSans-Bold';*/
    font-size: 18px;
    color: #1a89a7;
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
    height: 38px;
    line-height: 38px;
    text-indent: 16px;
    color: #666;
    cursor: pointer;
}

.rc-collapse > .rc-collapse-item {
    border-top: 1px solid #d9d9d9;
}

.rc-collapse {
    background-color: #f7f7f7;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
}

.patientInfo .form-group {
    margin-bottom: 10px !important;
}

/*.patientInfo .rc-collapse{*/
/*margin-top: 5px !important;*/
/*}*/
.patientInfo .rc-collapse-item-active .rc-collapse-header {
    background: #d7e7ea;
}

.patientInfo .rc-collapse-content-box .table tr:first-child td {
    border-top: none !important;
}

.patientInfo .rc-collapse {
    background: #f6f6f6 !important;
}

.rc-collapse {
    background-color: #f7f7f7;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
}

.rc-collapse-anim-active {
    transition: height 0.2s ease-out;
}

.rc-collapse > .rc-collapse-item {
    border-top: 1px solid #d9d9d9;
}

.rc-collapse > .rc-collapse-item:first-child {
    border-top: none;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
    height: 38px;
    line-height: 38px;
    text-indent: 16px;
    color: #666;
    cursor: pointer;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
    display: inline-block;
    content: '\20';
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 4px solid #666;
    vertical-align: middle;
    margin-right: 8px;
}

.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
    cursor: not-allowed;
    color: #999;
    background-color: #f3f3f3;
}

.rc-collapse-content {
    overflow: hidden;
    /*color: #666;*/
    padding: 0 16px;
    background-color: #fff;
}

.rc-collapse-content > .rc-collapse-content-box {
    margin-top: 16px;
    margin-bottom: 16px;
}

.rc-collapse-content-inactive {
    display: none;
}

.rc-collapse-item:last-child > .rc-collapse-content {
    border-radius: 0 0 3px 3px;
}

.rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #666;
    margin-right: 6px;
}

.patientInfo p {
    font-family: 'SegoeUI-Semibold', sans-serif;
    font-size: 16px;
    margin-bottom: 0;
    padding-right: 5px;
}

.color-secondary {
    color: #d58234;
}

.leftSection {
    width: 350px;
    float: left;
    margin-right: 20px;
}

.rightSection {
    float: left;
    width: calc(100% - 370px);
}

.BCTable .scroller {
    max-height: 420px;
    overflow: auto;
}

.scrolling.content.patientFamilyView {
    padding: 1.5rem .5rem !important;
}


.card .card-content {
    padding: 15px;
}

/*.familyView, .familyView .scrolling {*/
/*background: #edeff3 !important;*/
/*}*/
/*.familyView .card .card-header {*/
/*background: #fff;*/
/*}*/
.tableCustomStyle thead th {
    text-transform: uppercase;
    border-bottom: none !important;
    height: 44px;
}

.tableCustomStyle {
    margin-top: 0 !important;
}

.tableCustomStyle tbody tr:nth-child(2n) {
    background: #fcf5ef;
}

.patientInfo tr td {
    border-top: 1px solid #ececec !important;
    padding: 5px 0 !important;
}

.patientInfo tr:first-child td {
    border-top: none !important;
}

.patientInfo tr:first-child td .table tr td {
    border-top: 1px solid #ececec !important;
}

.leftSection .col-12, .leftSection .wd100 {
    width: 100%;
}

.patientInfo .col-12 {
    padding-left: 15px;
    padding-right: 15px;
}

.patientInfo table {
    margin-top: 0px;
}

.text-red {
    color: #f31e05 !important;
}

.text-green {
    color: #68c802 !important;
}

.phoneTolTipOuter a {
    color: #337ab7 !important;
    text-decoration: none;
}

.childPage-phoneTolTip {
    position: relative !important;
    left: 0 !important;
    width: 340px;
}

.phoneTolTip {
    position: absolute;
    left: 100%;
    background: #fff;
    width: 100%;
    z-index: 9;
    margin-top: 5px;
}

.phoneTolTipImg:first-child {
    border-top: none;
}

.phoneTolTipImg {
    border-top: 1px solid #ececec;
}

.childPage-phoneTolTip .phoneTolTipImg img {
    margin: 10px;
}

.phoneTolTipImg img {
    margin: 15px;
    cursor: pointer;
    width: 15px;
}

.patientInfo p {
    font-family: 'SegoeUI-Semibold', sans-serif;
    font-size: 16px;
    margin-bottom: 0;
    padding-right: 5px;
}

.phoneTolTipP {
    float: right;
    margin: 8px 0 !important;
    width: 135px;
    line-height: 14px;
    margin-bottom: 10px;
    font-size: 13px !important;
    text-align: right;
}

.patientInfo label {
    display: inline-block;
    max-width: 100%;
    font-weight: bold;
}

/*-----------------------------------------------------------Practices------------------------------------------------*/
.ui.card.practices {
    width: calc(25% - 15px);
    float: left;
    margin-right: 15px;
}

.ui.card.practices:nth-child(4n+1) {
    clear: both;
}

.practices .imgHolder {
    border: 1px solid #ededed;
    border-radius: 4px;
    height: 202px;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
}

.practices h4 {
    font-family: 'SegoeUI-Semibold', sans-serif;
    font-size: 18px;
    margin: 10px 0 0 !important;
    cursor: pointer;
    min-height: 36px;
}

.practices h4:hover {
    color: #D06F2A;
}

.doctors h6 {
    min-height: 28px;
}

.practiceLocation div {
    min-height: 30px;
}

.practices h6 {
    font-family: 'SegoeUI-Semibold', sans-serif;
    font-size: 14px;
}

.practices .practiceImage {
    max-height: 100%;
    max-width: 100%;
    width: auto;
}

.ui.modal .practices .practiceImage {
    /*max-height: unset;*/
    max-height: 470px;
    max-width: 100%;
}

.ui.modal .practices .ui.ribbon.label {
    top: 20px;
}

.practices label, .practices a {
    font-size: 13px !important;
}

.practices a {
    text-decoration: none;
    color: #262626;
}

.practices a:hover {
    color: #707070;
}

.practices .icon {
    color: #D06F2A;
}

.practices .ui.ribbon.label {
    position: absolute;
    left: 2px;
    top: -50px;
    background: #D06F2A;
    border-radius: 0;
    color: #fff;
    border-color: #B15514;
    font-weight: normal;
    padding: 5px 15px;
}

.customDivider {
    border-top: 1px dashed #ddd !important;
    border-bottom: none !important;
    margin: 0 !important;
}

.btnPracticeView .button {
    background: #fff !important;
}

.btnPracticeView .button + .button {
    border-left: 1px solid rgba(34, 36, 38, 0.15);
}

.btnPracticeView .button.active {
    color: #D06F2A;
}

.btnPracticeView .button.active:hover {
    color: #D06F2A;
}

.modals .ui.card {
    width: auto;
}

button.gm-ui-hover-effect {
    top: 0 !important;
    right: 3px !important;
}

button.gm-ui-hover-effect img {
    width: 20px !important;
    height: 20px !important;
}

.openingHours span {
    font-family: 'SegoeUI-Semibold', sans-serif;
    min-width: 40px;
    float: left;
}

.openingHours label {
    margin-bottom: 4px;
}

.scroller {
    max-height: 80px;
    overflow-y: scroll;
}

.weather table {
    width: 100%;
}

.weather table td:last-child {
    border-right: none;
}

.weather table td {
    border-right: 1px solid #DDD;
    padding: 5px;
    font-size: 12px;
    text-align: center;
}

/*-----------------------------------------------------------Map------------------------------------------------*/
.gm-style .gm-style-iw-c {
    padding-left: 0 !important;
}

.nextDaysEvent {
    background: #eaf6fd;
    padding: 2px 2px;
}

.eventTitle {
    padding: 2px 2px;
}

.downloadApp-img1, .downloadApp-img2 {
    max-height: 100%;
    max-width: 100%;
}

.iphone-steps {
    margin-top: 30px;
}

.rbc-event {
    padding: 0px !important;
}

.rbc-row-segment {
    word-wrap: normal;
}

.care-delta .rbc-row-segment .rbc-event-content {
    white-space: nowrap !important;
}

.pr-sm-img {
    margin: 10px;
    max-height: 120px;
}

.selected-img {
    border: 2px solid #D06F2A;
    padding: 2px;
}

.sm-img-container {
    height: 150px;
    overflow-x: auto;
    width: 100%;
    display: inline-flex;
}

.next-btn, .prev-btn {
    font-size: 40px;
    position: absolute;
    top: 36%;
    color: #fff;
    width: 40px;
    cursor: pointer;
    opacity: 0.5;
}

.next-btn {
    right: 10px;

}

.prev-btn {
    left: 20px;
}

.next-btn .icon, .prev-btn .icon {
    color: #fff !important;
}

.next-btn:hover, .prev-btn:hover {
    opacity: 1;
}

.tooltip {
    position: relative !important;
    display: inline-block !important;
    opacity: unset !important;
    text-align: center;
    width: 100%;
    z-index: auto !important;
}

.rbc-row-content {
    z-index: auto !important;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 15px;

    /* Position the tooltip */
    position: absolute;
    z-index: 999;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.max-temp {
    color: #D06F2A;
}

.min-temp {
    color: #908C8C;
}

.time-span {
    float: right;
    color: #D06F2A;
}

.react-datepicker-wrapper, .react-datepicker-wrapper div {
    width: 100%;
}

.dentist-view-container .col-md-12 {
    margin-top: 10px;
}

.dentist-view-container {
    padding-bottom: 20px !important;
}

.document-area {
    border: 1px solid #DDDDDD;
    min-height: 200px;
    background-size: 40%;
    background-position: top;
    text-align: center;
}

.document-img {
    max-width: 100%;
    max-height: 200px;
}

.document-area .span-1 {
    position: absolute;
    bottom: 10px;
    left: 40px;
}

.document-area .span-2 {
    position: absolute;
    bottom: 10px;
    right: 40px;
}

.document-area .span-3 {
    /*position: absolute;*/
    top: 5px;
}

.document-area .input-group-btn {
    margin-bottom: 0px;
    cursor: pointer;
}

.icon-img {
    max-width: 100px;
}

.del-icon {
    cursor: pointer;
}

.expiredDoc {
    position: absolute;
    z-index: 9;
    left: 35px;
    top: 5%;
    opacity: 0.3;
}

.approvalDoc {
    position: absolute;
    z-index: 9;
    left: 35px;
    top: 50%;
    opacity: 0.3;
}

.document-pdf {
    width: 90%;
    height: 300px;
}

.dentist-document-pdf {
    width: 90%;
    height: 180px;
}

.help-files-pdf {
    width: 100%;
}

.crop-img-container {
    min-height: 200px;
}


/*-----------------------------------------------------------notification------------------------------------------------*/
.notification {
    position: relative;
    margin-right: 20px;
}

.notification .bellIcon {
    font-size: 16px !important;
    margin-top: 20px !important;
}

.notification .ui.label.circular.bellIconLabel {
    background: #d67d38 !important;
    color: #fff !important;
    box-shadow: none !important;
    position: absolute;
    min-height: 19px !important;
    min-width: 19px !important;
    line-height: 8px !important;
    left: 9px;
    top: 10px;
    padding: 5px !important;
}

.notification-list {
    width: 200px;
}

/*-----------------------------------------------------------Help------------------------------------------------*/
.helpMark {
    margin: -15px 20px 0 10px;
    float: right;
}

.helpList {
    padding-left: 15px;
}

.helpList li + li {
    margin-top: 15px;
    border-top: 1px solid #e1e1e1;
    padding-top: 10px;
}

.helpList li strong {
    font-size: 14px;
}

.helpList li span + span {
    margin-left: 30px;
}

.helpList li span:hover .linkText {
    text-decoration: underline;
}

.helpVideo {
    max-width: 100%;
}

.helpVideo + .helpVideo {
    margin-top: 20px;
}

.helpPdf iframe {
    width: 100%;
    min-height: 500px;
}

.helpPdf iframe + iframe {
    margin-top: 20px;
}

/*------------------------------FreezeTable----------------------*/
.FreezeTable {
    max-width: 100%;
    max-height: 60vh;
    overflow: auto;
    position: sticky;
}

.freezeHeight {
    max-height: 80vh !important;
}

.FreezeTable table {
    position: relative;
    border-collapse: collapse;
}

.FreezeTable thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    z-index: 1;
}

.FreezeTable .adjustment thead th:first-child {
    left: 0;
    z-index: 2;
}

.FreezeTable .adjustment thead th:nth-child(2) {
    left: 90px;
    z-index: 2;
}

.FreezeTable .adjustment thead th:nth-child(3) {
    left: 182px;
    z-index: 2;
}

.FreezeTable .adjustment tbody td:first-child,
.FreezeTable .adjustment tbody td:nth-child(2),
.FreezeTable .adjustment tbody td:nth-child(3) {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
}

.FreezeTable .adjustment tbody tr:nth-child(odd) td:first-child,
.FreezeTable .adjustment tbody tr:nth-child(odd) td:nth-child(2),
.FreezeTable .adjustment tbody tr:nth-child(odd) td:nth-child(3) {
    background: #edf3f4;
}

.FreezeTable .adjustment tbody tr:nth-child(2n) td:first-child,
.FreezeTable .adjustment tbody tr:nth-child(2n) td:nth-child(2),
.FreezeTable .adjustment tbody tr:nth-child(2n) td:nth-child(3) {
    background: #fff;
}

.FreezeTable .adjustment tbody td:first-child {
    left: 0;
    z-index: 1;
}

.FreezeTable .adjustment tbody td:nth-child(2) {
    left: 90px;
    z-index: 1;
}

.FreezeTable .adjustment tbody td:nth-child(3) {
    left: 182px;
    z-index: 1;
}

.FreezeTable.newFlow td {
    position: static !important;
}


.claimsFreezeTable {
    max-width: 100%;
    max-height: 50vh;
    overflow: scroll;
    position: sticky;
}

.claimsFreezeTable table {
    position: relative;
    border-collapse: collapse;
}

.claimsFreezeTable thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
}

/*-----------------------------------------------------Report Table--------------------------------------------------*/
.stickyTable {
    max-width: 100%;
    max-height: 60vh;
    overflow: scroll;
    position: sticky;
}

.stickyTable table {
    position: relative;
    border-collapse: collapse;
}

.stickyTable thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
}

.stickyTable tbody tr:nth-child(2n) {
    background: #fcf5ef;
}

/*-----------------------------------------------------------Responsive------------------------------------------------*/
@media (max-width: 1700px) {
    .table thead th {
        min-width: 60px;
        word-wrap: break-word
    }
}

@media (max-width: 1500px) {
    .ui.card.practices {
        width: calc(33.33% - 15px);
    }

    .ui.card.practices:nth-child(3n+1) {
        clear: both;
    }

    .ui.card.practices:nth-child(4n+1) {
        clear: none;
    }
}

@media (max-width: 1199px) {
    .cdp-udr .ui.visible.thin.left.sidebar ~ .fixed, .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher {
        transform: none;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment {
        padding-right: 0;
    }

    .cdp-udr .ui.thin.left.sidebar ~ .pusher .ui.secondary.menu .item.toggle {
        margin-left: 0 !important;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.secondary.menu .item.toggle {
        margin-left: 300px !important;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment .btnFeedback {
        right: 0;
    }

    .ui.horizontal.list.topValues > .item {
        margin-left: 25px;
    }
}

@media (max-width: 1080px) {
    .ui.card.practices {
        width: calc(50% - 15px);
    }

    .ui.card.practices:nth-child(4n+1),
    .ui.card.practices:nth-child(3n+1) {
        clear: none;
    }

    .ui.card.practices:nth-child(2n+1) {
        clear: both;
    }
}

@media (max-width: 991px) {

    .ui.pointing.secondary.menu .item {
        padding: 20px 30px !important;
    }

    .ui.horizontal.list.topValues {
        text-align: right;
    }

    .leftSection {
        width: 300px;
        margin-right: 15px;
    }

    .rightSection {
        width: calc(100% - 315px);
    }
}

@media (max-width: 767px) {
    .headingMain {
        margin-bottom: 10px;
    }

    .containerMain {
        padding-top: 20px;
    }

    .login .sectionRight .row {
        align-items: unset;
    }

    .login .google {
        position: absolute;
        bottom: 110px;
        left: 50%;
        transform: translateX(-50%);
    }

    .login h1 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 160px;
        color: #fff !important;
    }

    .login .downloadApp {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        background: #d46e2e !important;
        color: #fff !important;
    }

    .login .downloadApp:hover {
        background: #d16230 !important;

    }

    .login .loginLogo {
        margin-top: 50px;
        width: 250px;
        margin-bottom: 0;
    }

    .ui.button {
        padding: 10px 20px !important;
    }

    .cdp-udr .btnNextPre .ui.button {
        padding: 10px !important;
    }

    .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button + button {
        height: 34px;
        width: 34px;
    }

    .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button:first-child {
        padding: 10px 20px;
    }

    .rbc-calendar .rbc-toolbar .rbc-btn-group:last-child button {
        padding: 9px 12px
    }

    .cdp-udr .ui.thin.left.sidebar ~ .fixed, .cdp-udr .ui.thin.left.sidebar ~ .pusher {
        transform: translate3d(0, 0px, 0px);
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .fixed, .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher {
        /*transform: translate3d(300px, 0px, 0px);*/
        transform: none;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .btnFeedback,
    .btnFeedback {
        right: 0 !important;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .btnFeedback,
    .btnFeedback {
        right: 0;
    }

    .cdp-udr .ui.basic.segment {
        padding: 0;
    }

    .cdp-udr .ui.thin.left.sidebar {
        width: 0;
    }

    .rbc-event-content, .rbc-event-content sub {
        font-size: 8px;
    }

    .rbc-event-content span {
        display: block;
    }

    .rbc-event {
        border-radius: 0 !important;
    }

    .rbc-row-segment {
        line-height: normal;
    }

    .calendar-container .rbc-calendar {
        /*min-height: 600px;*/
    }

    .login .sectionLeft {
        display: none;
    }

    .login .sectionRight {
        height: 100vh;
        background: url("../images/LoginBgMobile.jpg") no-repeat;
        background-size: cover;
        background-position: 50%;
    }

    .cdp-udr .ui.card .content, .ui.card .content.header-style {
        padding: 10px;
    }

    .ui.modal .actions {
        padding: 15px 8px !important;
    }

    .ui.modal .header {
        padding: 15px 8px !important;
    }

    .cdp-udr .ui.secondary.menu .item.toggle {
        position: absolute;
        z-index: 1;
    }

    .rbc-calendar .rbc-header {
        font-size: 13px;
        padding: 8px 2px !important;
    }

    .cdp-udr .ui.secondary.menu .logoSmall {
        display: block;
        margin: 8px 0 8px 62px;
        float: left;
    }

    .cdp-udr .ui.secondary.menu .item.toggle:hover {
        background: #fff;
    }

    .ui.modal .content > .description {
        padding: 0 !important;
    }

    .ui.modal .content > .description.mt20 {
        margin-top: 15px !important;
    }

    .ui.modal .table {
        margin-bottom: 0 !important;
    }

    .table {
        margin-top: 10px;
    }

    .table td {
        padding: 8px 0.75rem;
    }

    .table thead th > div {
        min-height: 34px;
    }

    .leftSection {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        margin-top: 15px;
    }

    .rightSection {
        width: 100%;
    }

    .cdp-udr .ui.thin.left.sidebar.visible .logo {
        width: 180px;
        padding: 15px 0;
    }

    .cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > a > .icon, .cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title .icon {
        font-size: 14px;
    }

    .ui.modal.familyView > .content {
        padding: 15px 0 !important;
    }

    .ui.modal > .content {
        padding: 15px !important;
    }

    .ui.modal > .content.pt-0 {
        padding-top: 0 !important;
    }

    .ui.modal .practiceImage {
        max-height: 200px !important;
        width: auto;
        margin-bottom: 15px;
    }

    .cdp-udr .gm-style-iw-d .ui.card .content {
        padding: 0 15px !important;
    }

    .topFilters .ui.dropdown,
    .topFilters .ui.input {
        margin-bottom: 10px !important;
    }
}

@media (max-width: 766px) {
    .rbc-calendar .rbc-toolbar .rbc-toolbar-label {
        font-size: 18px;
    }

    h3 {
        font-size: 18px;
    }
}

@media (max-width: 640px) {
    .rbc-calendar .rbc-toolbar {
        display: block;
    }

    .rbc-calendar .rbc-toolbar .rbc-btn-group:last-child {
        display: block;
        text-align: right;
        margin-top: 10px;
    }

}

@media (max-width: 575px) {
    .containerMain {
        padding-top: 15px;
    }

    .cdp-udr .ui.huge.header {
        font-size: 16px;
    }

    .cdp-udr .ui.huge.header span + span {
        font-size: 15px;
    }

    .practiceTop, .careTop {
        width: 100%;
        text-align: right;
    }

    .practiceTop .ui.input {
        float: left;
        width: 100%;
        margin-top: 10px;
    }

    .practiceTop .ui.dropdown {
        margin-right: 0 !important;
    }

    .careTop .ui.horizontal.list > .item:first-child,
    .careTop .ui.horizontal.list > .item:first-child .ui.dropown {
        width: 100%;
    }

    .ui.horizontal.list.topValues {
        text-align: left;
    }

    .ui.horizontal.list.topValues > .item {
        margin-left: 0;
    }

    .careTop .ui.horizontal.list > .item + .item,
    .payOrthoTop .ui.horizontal.list > .item + .item {
        width: 50%;
    }

    .ui.horizontal.list > .item + .item {
        margin-top: 10px;
    }

    .topDropdown, .topDropdown .ui.dropdown,
    .topDropdown .ui.input {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px !important;
    }

    .topDropdown .ui.buttons .ui.dropdown {
        width: auto;
    }

    .payOrthoTop .ui.horizontal.list.topValues > .item:first-child {
        width: 100%;
    }

    /*.cdp-udr .ui.secondary.menu {*/
    /*height: auto;*/
    /*display: flow-root;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .item {*/
    /*color: #273140;*/
    /*padding: 21px 25px;*/
    /*margin: 0;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .item:hover {*/
    /*color: #273140 !important;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .icon.setting {*/
    /*width: auto;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .dropdown:hover {*/
    /*background: #fff;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .dropdown .menu {*/
    /*border-radius: 0 0 4px 4px !important;*/
    /*left: -80% !important;*/
    /*margin-top: 0 !important;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .dropdown.user {*/
    /*padding: 16px;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .dropdown.user .text {*/
    /*margin-top: 4px;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .right.menu .item {*/
    /*padding: 21px 20px;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .right.menu #search {*/
    /*display: none;*/
    /*}*/
    .cdp-udr.ui.card .content .ui.form, .ui.card .content.header-style .ui.form, .cdp-udr .ui.card .content .ui.fluid.selection.dropdown, .cdp-udr .ui.card .content.header-style .ui.fluid.selection.dropdown {
        width: 100%;
    }

    .content-left {
        width: 100%;
    }

    .ui.huge.header.bgColor-Yellow span:nth-child(2) {
        display: none;
    }

    .ui.huge.header.bgColor-Yellow span:nth-child(3) {
        display: block;
    }

    .ui.card.patientDetails i.user {
        display: none;
    }

    .ui.card.patientDetails .row:nth-child(n+2) .col-6:first-child, .ui.card.patientDetails .row:nth-child(n+2) .col-8 {
        padding-left: 10px;
    }

    .questions select, .questions .ui.input input, .questions .ui.input {
        width: 100% !important;
    }

    .ui.steps i.icon {
        font-size: 36px !important;
    }

    .col.dropDown {
        flex-basis: 100%;
    }

    .ui.mini.modal {
        width: 348px !important;
    }

    .filter .location, .filter .day, .filter .appointment {
        width: 100%;
        margin-top: 15px;
    }

    .filter .location div:first-child, .filter .day div:first-child, .filter .appointment div:first-child {
        width: 100%;
    }

    /*.ui.pointing.secondary.menu .item + .item, .ui.pointing.secondary.menu .item + .item.active:hover {*/
    /*border-left: none !important;*/
    /*border-top: 1px solid rgba(34, 36, 38, 0.15) !important;;*/
    /*}*/
    .ui.pointing.secondary.menu .item {
        font-size: 0;
        padding: 15px 30px !important;
    }

    .ui.pointing.secondary.menu .item i.icon {
        font-size: 16px;
        margin-right: 0;
    }

    .cdp-udr .ui.thin.left.sidebar.visible {
        width: 240px;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.secondary.menu .item.toggle {
        margin-left: 240px !important;
    }

    .ui.card.practices {
        width: calc(100% - 15px);
    }

    .__react_component_tooltip {
        /*width: 100%;*/
        left: 5px !important;
        right: 5px !important;
        padding: 10px !important;
    }

    .patientInfo label {
        margin-bottom: 0;
    }

    .patientInfo p {
        font-size: 13px;
    }

    .familyView .card {
        border: none;
    }

    .familyView .card .card-content {
        padding: 5px 0 0 0;
    }

    .familyView .card .card-header {
        padding: 0;
        background: transparent !important;
        border-bottom: none;
    }

    .familyView .tableCustomStyle {
        margin-top: 5px !important;
    }

    h3.headingSub {
        color: #262626 !important;
    }

    .table thead th {
        min-width: 90px !important;
        padding: 2px 6px
    }

    .table td {
        padding: 8px 6px;
    }

    h3 {
        font-size: 16px;
    }

    h2 {
        font-size: 22px;
    }

    .rbc-calendar .rbc-time-view .rbc-header {
        font-size: 11px;
    }

    .rbc-calendar .rbc-time-view .rbc-header span {
        line-height: 24px;
    }

    .rbc-calendar .rbc-toolbar .rbc-toolbar-label {
        font-size: 15px;
        margin-left: 5px;
    }
}

/* ------------Claims View --------------- */
.claims-container {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-template-rows: auto;
    grid-template-areas: "controls controls ."
                          "claimstable claimstable claimstable";
    padding: 10px;
    background: #FFFFFF;
}

.claims-controls {
    grid-area: controls;
    padding-left: 10px;
}

.claims-table {
    grid-area: claimstable;
}

.ui.card.claims .ui.segment {
    box-shadow: none;
    border: none;
    padding: 0;
}

@media (max-width: 420px) {
    .cdp-udr .ui.secondary.menu .right.menu .item {
        padding: 21px 10px;
    }

    .cdp-udr .ui.secondary.menu .dropdown.user {
        padding: 17px 0px;
    }

    .cdp-udr .ui.secondary.menu .dropdown.user .text {
        margin-top: 4px;
    }

    .btnSend {
        float: right;
    }

}

@media (min-width: 768px) {
    .doctorList {
        margin-bottom: 15px;
    }
}

@media (min-width: 778px) {
    .downloadApp {
        display: none !important;
    }

    .mobile-help-link {
        display: none !important;
    }
}

@media (max-width: 375px) {
    .careTop .ui.horizontal.list.topValues > .item span b {
        display: block;
    }

    .user .userName {
        display: none;
    }

    .openingHours span {
        min-width: 34px;
    }
}

@media (max-width: 360px) {
    .openingHours span {
        min-width: 50px;
    }

    .openingHours .col-6 {
        width: 100% !important;
        flex: 100%;
        max-width: 100% !important;
    }

    .openingHours .col-6.pl-2 {
        padding-left: 15px !important;
    }

    .openingHours .col-6.pl-2 label {
        border-top: 1px dashed #ddd;
        width: 100%;
    }
}

@media only screen and (min-width:320px) and  (max-width: 1280px) {
    .FreezeTable table {
     overflow-x: auto;
 }
    .FreezeTable table tbody tr td:first-child,.FreezeTable table tr thead th:first-child {
        position: sticky!important;
        left: 0;
    }
    .FreezeTable table tbody tr:nth-of-type(odd) td ,.FreezeTable table thead tr th:first-child{
        background-color: #F9FAFB !important;
    }

    .FreezeTable table tbody tr:nth-of-type(even) td {
        background-color: #FFF !important;
    }

}

.campaign-filters label {
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 3px;
}

.campaign-filters input, .campaign-filters .form-group {
    float: left;
    width: 100%;
}

.campaign-filters .form-group {
    float: left;
    width: 100%;
}

.filters input, .campaign-filters input {
    height: 36px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
}

.campaign-filters input[type="checkbox"] {
    height: unset;
}

/*.cdp-udr .row {*/
/*    margin-right: -10px;*/
/*    margin-left: -10px;*/
/*}*/

.width120 {
    width: 120px;
}

.campaign-filters input[type="file"] {
    border: none;
}

.vid-img-dlt {
    max-height: 150px;
    max-width: 80%;
}

.ht-200 {
    height: 200px;
}

.addMore {
    font-size: 20px;
    font-weight: 600;
    color: #d67d38;
    cursor: pointer;
}

.repeatChkBox {
    float: left;
    margin: 10px 15px 0px 0px;
}

.repeatChkBox input[type="checkbox"] {
    margin-right: 5px;
}

.campaign-filters input[type="checkbox"] {
    width: unset;
}

.capitalize {
    text-transform: capitalize;
}

.modalCenter {
    margin: 0 auto !important;
    margin-top: 20px !important;
}

.clickable {
    cursor: pointer;
}

/*-----------------------------------------------------------datePicker------------------------------------------------*/
.datePicker {
    /*display: inline-block;
    position: relative;*/
}

.datePicker .react-datepicker {
    box-shadow: 0px 0px 12px #bbb;
    border: none !important;
}

.datePicker input {
    border: 1px solid #dededf;
    border-radius: 4px;
    padding: 0.67857143em 2.1em 0.67857143em 1em;
    height: 38px;
    outline: none;
}

.datePicker .icon.calendar {
    /*position: absolute;*/
    right: 10px;
    top: 9px;
}

.datePicker .react-datepicker__day--keyboard-selected {
    background-color: #d16f26 !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.nF .react-datepicker-popper[data-placement^="bottom"] {
    margin-left: 330px !important;
}

/*---------------------------------------Modal--------------------------------------*/
.ui.dimmer {
    background-color: rgba(9, 50, 62, 0.80) !important;
}

.ui.modal .header,
.ui.modal .content,
.ui.modal .actions {
    padding: 15px;
}

.ui.modal .header {
    background: #e7eff1 !important;
    color: #176279 !important;
    font-family: 'SegoeUI-Semibold', sans-serif !important;
    font-size: 16px !important;
    text-transform: uppercase;
    border-bottom: 1px solid #bdd3d9;
}

.ui.modal .actions {
    background: #f3f7f8 !important;
    text-align: right;
    border-top: 1px solid #dee9ec;
    border-radius: 0 0 0.28571429rem 0.28571429rem !important;
}

.ui.modal > .close {
    color: #fff !important;
}

.ui.small.modal.confirmModal {
    width: 400px !important;
}

.ui.small.modal.confirmModal .content {
    padding: 30px 15px !important;
}

.ui.small.modal.confirmModal .ui.button {
    padding: 13px 30px !important;
}

.ui.small.modal.confirmModal .ui.button {
    background: #8ea8b2 !important;
}

.ui.small.modal.confirmModal .ui.button:hover {
    background: #7e9ca7 !important;
}

.ui.small.modal.confirmModal .ui.button.primary {
    background: #e90000 !important;
}

.ui.small.modal.confirmModal .ui.button.primary:hover {
    background: #d40404 !important;
}

.modalFooter {
    background: #f3f7f8;
    border-top: 1px solid #dee9ec;
    padding: 15px 0 !important;
}

.ui.modal .ui.card.event .content {
    padding: 0 !important;
}

.imageModal img {
    margin: 0 auto;
}

.ui.modal .ui.card {
    border: 1px solid #dee9ec;
}

.ui.fullscreen.scrolling.modal.active, .ui.fullscreen.modal.active {
    left: auto !important;
}

.members-icon {
    background-image: url('../images/membersIcon.png');
    background-repeat: no-repeat;
    background-size: 18px 18px;
    padding-bottom: 18px;
}

.claims-icon {
    background-image: url('../images/claimsIcon.png');
    background-repeat: no-repeat;
    background-size: 18px 18px;
    padding-bottom: 18px;
}

@media (max-width: 800px) {
    .row:nth-of-type(2) {
        flex-direction: column !important;
    }

    h3 {
        margin-bottom: 1px !important;
    }

    h4 {
        margin-top: 1px !important;
    }

    .claims-container {
        grid-template-columns: auto 400% auto;
    }

}

.pl-1 {
    padding-left: 1em !important;
}

.pl-2 {
    padding-left: 2em !important;
}

.text-left {
    text-align: left;
}

.sidebar.menu .menu-item {
    padding: 16px 12px;
    display: block;
}

.sidebar.menu.visible .menu-item i {
    margin-right: 10px;
}


.sidebar.menu .menu-item {
    color: #222222;
}

i.icon.card {
    background: none;
    padding: 0;
}

/*.menu-item.active, .content.active, .active.title{*/
/*    color: #000 !important;*/
/*    background: #ebcead;*/
/*}*/

.ui.menu > .ui.accordion > .ui.accordion > .title:not(.active) .menu-item:hover{
    background: #f1f1f1;
}

.ui.menu > .ui.accordion > .ui.accordion > .title.active {
    background: #e6b288;
    color: #000 !important;
}

.sidebar.menu .ui.accordion .active.content, .sidebar.menu .ui.accordion .accordion .active.content {
    background: #eeceaf;
    padding-top: 0;
}

.ui.menu > .ui.accordion > .ui.accordion > .content.active > .ui.accordion > .title:not(.active) {
    margin-left: 1px;
    border-left: 1px solid #e5a361;
}

.ui.menu > .ui.accordion > .ui.accordion > .content.active > .ui.accordion > .content.active {
    background: #e1b285;
}
.ui.menu > .ui.accordion > .ui.accordion > .content.active > .ui.accordion > .content.active .ui.accordion > .title:not(.active){
    margin-left: 1px;
    border-left: 1px solid #d3883e;
}
.ui.menu > .ui.accordion > .ui.accordion > .content.active > .ui.accordion > .content.active .ui.accordion > .title.active{
    border-left: 3px solid #cd7a28;
}
.ui.menu > .ui.accordion > .ui.accordion > .content.active > .ui.accordion > .content.active .ui.accordion > .title.active .menu-item.active{
    background: #d5a374;
}


.ui.menu > .ui.accordion > .ui.accordion > .content.active > .ui.accordion > .content.active > .ui.accordion span {
    padding: 12px;
}

.ui.menu > .ui.accordion > .ui.accordion > .content.active > .ui.accordion > .title.active {
    border-left: 3px solid #cd7a28;
    color: #000000;
    background: #edc095;
}

/*.content.active{*/
/*    background: #f1d9c7;*/
/*    padding-top: 0 !important;*/
/*}*/

.sidebar.menu .accordion {
    margin-top: 0;
}

.sidebar.menu .accordion .title {
    padding: 0;
}

.cell-green {
    background-color: green !important;;
}

.cell-yellow {
    background-color: yellow !important;;
}

